import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import Home from "./home";
import BoardSelection from "./board_selection";
import Entries from "./entries";
import GamePlay from "./gameplay";
import { usePageTracking } from "./hooks/use_page_tracking";

const theme = extendTheme({
  colors: {
    brand: {
      timberwolf: "#dedbd2",
      outerSpace: "#4a5759",
      champagnePink: "#f7e1d7",
      ashGray: "#b0c4b1",
      cherryBlossomPink: "#edafb8",
    },
    cherryBlossomPink: {
      50: "#ffe8ed",
      100: "#f1c1c8",
      200: "#e698a3",
      300: "#db6f7f",
      400: "#d1485a",
      500: "#b82f41",
      600: "#902532",
      700: "#661924",
      800: "#3e0e15",
      900: "#190306",
    },
  },
});

function App() {
  usePageTracking();

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="step-1" element={<BoardSelection />} />
        <Route path="step-2" element={<Entries />} />
        <Route path="gameplay" element={<GamePlay />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
