import React from "react";
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledNewBoardButton = styled(Button)`
  margin-right: 1em;
`;

function RestartConfirmation({ onClose: onRestartModalClose }) {
  const navigate = useNavigate();

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please Confirm</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure? You will lose all your current entries.
        </ModalBody>
        <ModalFooter>
          <StyledNewBoardButton
            colorScheme="cherryBlossomPink"
            onClick={() => {
              window.gtag("event", "click", {
                event_category: "button_click",
                event_label: "restart_from_step_2",
              });
              localStorage.removeItem("dataArray");
              localStorage.removeItem("boardSelection");
              navigate("/step-1");
            }}
          >
            Yes, restart
          </StyledNewBoardButton>
          <Button variant="secondary" mr={3} onClick={onRestartModalClose}>
            No, continue working
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

export default RestartConfirmation;
