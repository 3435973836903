import React from "react";
import styled from "styled-components";
import {
  FormControl,
  FormLabel,
  Modal,
  useDisclosure,
  Textarea,
} from "@chakra-ui/react";
import { StyledPageWrapper, StyledButton, StyledHeading } from "./home";
import { StyledErrorMessage } from "./board_selection";
import EntriesConfirmation from "./entries_confirmation";
import RestartConfirmation from "./restart_confirmation";

export const StyledHelperText = styled.p`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const StyledTextArea = styled(Textarea)`
  margin-bottom: 2em;
`;

const StyledFormHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCharFont = styled.p`
  font-size: 0.8em;
`;

function Entries() {
  const {
    isOpen: isGenerateBoardModalOpen,
    onOpen: onGenerateBoardModalOpen,
    onClose: onGenerateBoardModalClose,
  } = useDisclosure();
  const {
    isOpen: isRestartModalOpen,
    onOpen: onRestartModalOpen,
    onClose: onRestartModalClose,
  } = useDisclosure();
  React.useEffect(() => {
    document.title = "Entries";
  }, []);
  const [error, setError] = React.useState(false);

  const boardSelection = localStorage.getItem("boardSelection");

  const chosenBoardSlots =
    boardSelection === "25"
      ? 24
      : parseInt(localStorage.getItem("boardSelection"));

  const [dataArray, setDataArray] = React.useState(() => {
    const dataArrayObject = JSON.parse(localStorage.getItem("dataArray"));
    if (dataArrayObject) {
      return dataArrayObject;
    } else {
      let dataArray = new Array(chosenBoardSlots);

      for (let i = 0; i < chosenBoardSlots; i++) {
        dataArray[i] = { text: "", isChecked: false };
      }
      return dataArray;
    }
  });

  // this is to address the edge case of someone clicking the "Back" browser button from the game play page
  // this will prevent multiple FREE SPACE tiles from being added
  const foundFreeSpaceTile = dataArray.find(
    (element) => element.text === "FREE SPACE" && element.standardTile === true
  );

  if (foundFreeSpaceTile) {
    const index = dataArray.indexOf(foundFreeSpaceTile);
    dataArray.splice(index, 1);
  }

  const onInputChange = (text, index) => {
    let dataArrayCopy = [...dataArray];
    dataArrayCopy[index].text = text;
    setDataArray(dataArrayCopy);
    localStorage.setItem("dataArray", JSON.stringify(dataArrayCopy));
  };

  return (
    <StyledPageWrapper>
      <Modal
        isOpen={isGenerateBoardModalOpen}
        onClose={onGenerateBoardModalClose}
      >
        {" "}
        <EntriesConfirmation
          onClose={onGenerateBoardModalClose}
          dataArray={dataArray}
          boardSelection={boardSelection}
        />
      </Modal>
      <Modal isOpen={isRestartModalOpen} onClose={onRestartModalClose}>
        <RestartConfirmation onClose={onRestartModalClose} />
      </Modal>
      <StyledHeading as="h1" color="brand.outerSpace">
        Step 2: Add your entries
      </StyledHeading>
      <StyledHelperText>
        Enter a possible stressful/frustrating scenario for your upcoming event.
      </StyledHelperText>

      {dataArray.map((dataCell, index) => (
        <FormControl key={index} isRequired>
          <StyledFormHead>
            <FormLabel>{`Scenario ${index + 1}`}</FormLabel>
            <StyledCharFont>
              {dataArray[index].text.length}/60 characters
            </StyledCharFont>
          </StyledFormHead>
          <StyledTextArea
            placeholder="e.g. Someone makes an insensitive comment about my lifestyle"
            minH="unset"
            height="3em"
            maxLength={60}
            required
            value={dataCell.text}
            onChange={(e) => {
              onInputChange(e.target.value, index);
            }}
          ></StyledTextArea>
        </FormControl>
      ))}
      {error && (
        <StyledErrorMessage>
          Error: Missing scenarios. Please fill out each scenario!
        </StyledErrorMessage>
      )}
      <StyledButton
        colorScheme="cherryBlossomPink"
        onClick={() => {
          const emptyDataCells = dataArray.filter(
            (cell) => cell.text === "" || cell.text === undefined
          );
          if (emptyDataCells.length > 0) {
            window.gtag("event", "click", {
              event_category: "button_click",
              event_label: "entries_errored",
            });
            setError(true);
          } else {
            window.gtag("event", "click", {
              event_category: "button_click",
              event_label: "entries_success",
            });
            onGenerateBoardModalOpen();
          }
        }}
      >
        Generate my board
      </StyledButton>
      <StyledButton
        onClick={() => {
          window.gtag("event", "click", {
            event_category: "button_click",
            event_label: "return_to_board_selection",
          });
          onRestartModalOpen();
        }}
      >
        Restart activity
      </StyledButton>
    </StyledPageWrapper>
  );
}

export default Entries;
