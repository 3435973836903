import React from "react";
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledNewBoardButton = styled(Button)`
  margin-right: 1em;
`;

function EntriesConfirmation({
  onClose: onGenerateBoardModalClose,
  dataArray,
  boardSelection,
}) {
  const navigate = useNavigate();

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Please Confirm</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure? Once you generate your board you will not be able to
          edit your tiles.
        </ModalBody>
        <ModalFooter>
          <StyledNewBoardButton
            colorScheme="cherryBlossomPink"
            onClick={() => {
              window.gtag("event", "click", {
                event_category: "button_click",
                event_label: "review_entries",
              });
              const scrambledDataArray = dataArray.sort(
                () => Math.random() - 0.5
              );
              if (boardSelection === "25") {
                scrambledDataArray.splice(12, 0, {
                  text: "FREE SPACE",
                  isChecked: true,
                  standardTile: true,
                });
              }
              localStorage.setItem(
                "dataArray",
                JSON.stringify(scrambledDataArray)
              );

              navigate("/gameplay");
            }}
          >
            Yes, generate my board
          </StyledNewBoardButton>
          <Button
            variant="secondary"
            mr={3}
            onClick={onGenerateBoardModalClose}
          >
            No, go back
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

export default EntriesConfirmation;
