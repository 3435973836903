import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { StyledPageWrapper, StyledButton, StyledHeading } from "./home";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { Stack } from "@chakra-ui/react";

export const StyledErrorMessage = styled.p`
  color: #8b0000;
  margin-top: 1em;
`;

function BoardSelection() {
  React.useEffect(() => {
    document.title = "Board Selection";
  }, []);
  const navigate = useNavigate();
  const [value, setValue] = React.useState(null);
  const [error, setError] = React.useState(false);

  return (
    <StyledPageWrapper>
      <StyledHeading as="h1" color="brand.outerSpace">
        Step 1: Choose your board
      </StyledHeading>

      <FormControl as="fieldset" isRequired>
        <FormLabel as="legend">
          Select the type of board you would like to build
        </FormLabel>
        <RadioGroup onChange={setValue} value={value}>
          <Stack direction="column">
            <Radio value="9">3 x 3 board (9 entries)</Radio>
            <Radio value="16">4 x 4 board (16 entries)</Radio>
            <Radio value="25">
              5 x 5 board (24 entries, 1 free center tile)
            </Radio>
          </Stack>
        </RadioGroup>
        {error && (
          <StyledErrorMessage>
            Error: Board selection is required. Please select one.
          </StyledErrorMessage>
        )}
      </FormControl>

      <StyledButton
        colorScheme="cherryBlossomPink"
        onClick={() => {
          if (value === null) {
            window.gtag("event", "click", {
              event_category: "button_click",
              event_label: "board_selection_errored",
            });
            setError(true);
          } else {
            window.gtag("event", "click", {
              event_category: "button_click",
              event_label: "board_selection_success",
            });
            localStorage.setItem("boardSelection", value);
            navigate("/step-2");
          }
        }}
      >
        Next
      </StyledButton>
      <StyledButton
        onClick={() => {
          window.gtag("event", "click", {
            event_category: "button_click",
            event_label: "return_to_home_page_from_step_1",
          });
          navigate("/");
        }}
      >
        Return to Homepage
      </StyledButton>
    </StyledPageWrapper>
  );
}

export default BoardSelection;
