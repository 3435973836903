import React from "react";
import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ConfettiExplosion from "react-confetti-explosion";
import styled from "styled-components";

const StyledNewBoardButton = styled(Button)`
  margin-right: 1em;
`;

function CongratulatoryMessage({ onClose: onCongratulatoryModalClose }) {
  const navigate = useNavigate();
  const [isExploding, setIsExploding] = React.useState(true);

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        {isExploding && (
          <ConfettiExplosion
            zIndex={10000}
            onComplete={() => {
              setIsExploding(false);
            }}
          />
        )}
        <ModalHeader>BINGO!</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Oh goodness. Our condolences for these chaotic moments. Please give
          yourself a well-deserved treat. You got this!
        </ModalBody>
        <ModalFooter>
          <StyledNewBoardButton
            colorScheme="cherryBlossomPink"
            onClick={() => {
              window.gtag("event", "click", {
                event_category: "button_click",
                event_label: "restart_game",
              });
              localStorage.clear();
              navigate("/step-1");
            }}
          >
            Create New Board
          </StyledNewBoardButton>
          <Button
            variant="secondary"
            mr={3}
            onClick={onCongratulatoryModalClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

export default CongratulatoryMessage;
