import React from "react";
import styled from "styled-components";
import { useDisclosure, Modal, Button } from "@chakra-ui/react";
import BingoBoard from "./bingo_board";
import CongratulatoryMessage from "./congratulatory_message";
import { checkWinCondition, chosenBoardSlotsToGridSize } from "./helper";
import { StyledHelperText } from "./entries";
import { StyledHeading } from "./home";
import RestartConfirmation from "./restart_confirmation";

const StyledGamePlayPageWrapper = styled.div`
  margin: 1em;
`;

const StyledButton = styled(Button)`
  margin-bottom: 2em;
`;

function GamePlay() {
  React.useEffect(() => {
    document.title = "Game Board";
  }, []);
  const chosenBoardSlots = parseInt(localStorage.getItem("boardSelection"));
  const {
    isOpen: isCongratulatoryModalOpen,
    onOpen: onCongratulatoryModalOpen,
    onClose: onCongratulatoryModalClose,
  } = useDisclosure();
  const {
    isOpen: isRestartModalOpen,
    onOpen: onRestartModalOpen,
    onClose: onRestartModalClose,
  } = useDisclosure();

  const [, setIsWin] = React.useState(false);

  const [dataArray, setDataArray] = React.useState(() => {
    return JSON.parse(localStorage.getItem("dataArray"));
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const gridSize = chosenBoardSlotsToGridSize(chosenBoardSlots);

  const handleClick = (index) => {
    let dataArrayCopy = [...dataArray];

    // free space should not be clickable
    if (chosenBoardSlots === 25 && index === 12) {
      return;
    }
    dataArrayCopy[index].isChecked = !dataArrayCopy[index].isChecked;
    localStorage.setItem("dataArray", JSON.stringify(dataArrayCopy));
    setDataArray(dataArrayCopy);

    const checkIsWin = checkWinCondition(dataArrayCopy, gridSize);
    setIsWin(checkIsWin);

    if (checkIsWin) {
      onCongratulatoryModalOpen();
    }
  };

  return (
    <>
      <Modal
        isOpen={isCongratulatoryModalOpen}
        onClose={onCongratulatoryModalClose}
      >
        {" "}
        <CongratulatoryMessage onClose={onCongratulatoryModalClose} />
      </Modal>
      <Modal isOpen={isRestartModalOpen} onClose={onRestartModalClose}>
        <RestartConfirmation onClose={onRestartModalClose} />
      </Modal>
      <StyledGamePlayPageWrapper>
        <StyledHeading as="h1" color="brand.outerSpace">
          Let the fun begin!
        </StyledHeading>
        <StyledHelperText>
          Each time a situation actually happens, click on the corresponding
          tile.
        </StyledHelperText>
        <StyledHelperText>
          For mobile users: If your text is long, you can rotate your device
          horizontally.
        </StyledHelperText>

        <StyledButton
          onClick={() => {
            window.gtag("event", "click", {
              event_category: "button_click",
              event_label: "return_to_board_selection_from_gameplay",
            });
            onRestartModalOpen();
          }}
        >
          Restart activity
        </StyledButton>

        <BingoBoard
          chosenBoardSlots={chosenBoardSlots}
          dataArrayObject={dataArray}
          handleCellOnClick={handleClick}
        />
      </StyledGamePlayPageWrapper>
    </>
  );
}

export default GamePlay;
