import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button, Heading } from "@chakra-ui/react";

export const StyledPageWrapper = styled.div`
  margin: 3em;
`;

export const StyledHeading = styled(Heading)`
  font-family: "Reenie Beanie", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5em;
  margin-bottom: 0.5em;
`;

const StyledParagraph = styled.p`
  margin-bottom: 1em;
`;

const StyledUnorderedList = styled.ul`
  margin-left: 2.5em;
`;

export const StyledButton = styled(Button)`
  margin-top: 2em;
  margin-right: 1.5em;
`;

function Home() {
  React.useEffect(() => {
    document.title = "Home";
  }, []);

  const navigate = useNavigate();

  return (
    <StyledPageWrapper>
      <StyledHeading as="h1" color="red">
        Chaotic Adventures
      </StyledHeading>
      <StyledParagraph>
        Welcome! Have you ever experienced the following?
      </StyledParagraph>
      <StyledParagraph>
        <StyledUnorderedList>
          <li>
            Felt nervous leading up to a stressful event like a big
            presentation, performance, etc
          </li>
          <li>
            Attended gatherings where you heard insensitive comments/questions{" "}
          </li>
          <li>
            ...just any other situation where you had a feeling it would be
            frustrating but you had no choice but to be there
          </li>
        </StyledUnorderedList>
      </StyledParagraph>
      <StyledParagraph>
        Chaotic Adventures is here to keep you company during those moments. It
        is a play on traditional bingo where each tile represents a chaotic
        scenario that you predict will happen during a future event.
      </StyledParagraph>
      <StyledParagraph>
        To help re-channel the pending stress/anxiety, before you head to the
        next gathering or work/school event, add each predicted
        chaotic/stressful scenario to the board. If your predicted scenario
        actually happens, check it off on the board.
      </StyledParagraph>
      <StyledParagraph>
        Like regular BINGO, you win when you cover all tiles in a whole row or
        column, or diagonally.
      </StyledParagraph>
      <StyledParagraph>Good Luck, and Have Fun!</StyledParagraph>
      <StyledButton
        colorScheme="cherryBlossomPink"
        onClick={() => {
          window.gtag("event", "click", {
            event_category: "button_click",
            event_label: "new_game",
          });
          localStorage.removeItem("dataArray");
          navigate("/step-1");
        }}
      >
        New Game
      </StyledButton>

      {localStorage.getItem("dataArray") !== null && (
        <StyledButton
          colorScheme="cherryBlossomPink"
          variant="outline"
          onClick={() => {
            window.gtag("event", "click", {
              event_category: "button_click",
              event_label: "continue_existing_game",
            });
            navigate("/gamePlay");
          }}
        >
          Continue Existing Game
        </StyledButton>
      )}
    </StyledPageWrapper>
  );
}

export default Home;
