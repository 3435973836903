import { ReactFitty } from "react-fitty";
import styled from "styled-components";
import { chosenBoardSlotsToGridSize } from "./helper";

const StyledParentBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.gridSize}, 1fr);
  grid-template-rows: repeat(${(props) => props.gridSize}, 1fr);
  grid-row-gap: 0.5em;
  grid-column-gap: 1em;
`;

const StyledSquare = styled.div`
  background-color: ${(props) => (props.isChecked ? "#C9EDDC" : "#FFD9E2")};
  padding: 0.5em;
  overflow: hidden;
  border-radius: 0.5em;
`;

function BingoBoard({ chosenBoardSlots, dataArrayObject, handleCellOnClick }) {
  return (
    <StyledParentBox gridSize={chosenBoardSlotsToGridSize(chosenBoardSlots)}>
      {dataArrayObject &&
        dataArrayObject.map((obj, index) => (
          <BingoSquare
            text={obj.text}
            isChecked={obj.isChecked}
            index={index}
            handleCellOnClick={handleCellOnClick}
          ></BingoSquare>
        ))}
    </StyledParentBox>
  );
}

function BingoSquare({ text, isChecked, index, handleCellOnClick }) {
  return (
    <StyledSquare
      isChecked={isChecked}
      onClick={() => {
        window.gtag("event", "click", {
          event_category: "tile",
          event_label: "tile_toggled",
        });
        handleCellOnClick(index);
      }}
    >
      <ReactFitty minSize={10} maxSize={20} wrapText={true}>
        {text}
      </ReactFitty>
    </StyledSquare>
  );
}

export default BingoBoard;
