// gridSize = 3
// 0 1 2
// 3 4 5
// 6 7 8

// gridSize = 4
// 0  1  2  3
// 4  5  6  7
// 8  9  10 11
// 12 13 14 15

// gridSize = 5
// 0  1  2  3  4
// 5  6  7  8  9
// 10 11 12 13 14
// 15 16 17 18 19
// 20 21 22 23 24

export function checkWinCondition(data, gridSize) {
  if (!data) {
    return false;
  }

  // horizontal checks
  for (let i = 0; i < gridSize; i++) {
    let tickCount = 0;
    const startIndex = i * gridSize;
    for (let j = 0; j < gridSize; j++) {
      if (data[i + j].isChecked) {
        tickCount++;
      }
    }
    if (tickCount === gridSize) {
      return true;
    }
  }

  // vertical checks
  for (let i = 0; i < gridSize; i++) {
    let tickCount = 0;
    const startIndex = i;
    for (let j = 0; j < gridSize; j++) {
      if (data[i + j * gridSize].isChecked) {
        tickCount++;
      }
    }
    if (tickCount === gridSize) {
      return true;
    }
  }

  // diagonal checks
  const gridSizeThreeIndices = [
    [0, 4, 8],
    [2, 4, 6],
  ];
  const gridSizeFourIndices = [
    [0, 5, 10, 15],
    [3, 6, 9, 12],
  ];
  const gridSizeFiveIndices = [
    [0, 6, 12, 18, 24],
    [4, 8, 12, 16, 20],
  ];

  let indices = null;
  if (gridSize === 3) {
    indices = gridSizeThreeIndices;
  } else if (gridSize == 4) {
    indices = gridSizeFourIndices;
  } else {
    indices = gridSizeFiveIndices;
  }

  return indices.some((subIndices) => {
    return subIndices.every((subIndex) => {
      return data[subIndex].isChecked;
    });
  });
}

export function chosenBoardSlotsToGridSize(boardSize) {
  if (boardSize === 25) {
    return 5;
  } else if (boardSize === 16) {
    return 4;
  } else {
    return 3;
  }
}
